$cyan-color: #66b1bb;
$red-color: #EF9A9A;
$purple-color: #CE93D8;
$green-color: #9db484;

:export {
	cyanColor: $cyan-color;
	redColor: $red-color;
	purpleColor: $purple-color;
	greenColor: $green-color;
}
