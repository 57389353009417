@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600&display=swap);

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: 'Nunito', source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.player-controlls-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: ". up ." "left center right" ". down ."; }

.player-controlls-icon {
  font-size: 20vw !important; }

.player-controlls-action {
  height: 33vw;
  display: flex;
  align-items: center;
  justify-content: center; }

.card-button {
  cursor: pointer; }

.home-page a, .toolbar a {
  text-decoration: none; }

.toolbar a {
  color: white; }

.player-controlls-left {
  grid-area: left; }

.player-controlls-right {
  grid-area: right; }

.player-controlls-middle {
  grid-area: center; }

.player-controlls-up {
  grid-area: up; }

.player-controlls-down {
  grid-area: down; }

@media (orientation: landscape) {
  .player-controlls-action {
    height: 33vh; } }

.button-answer-icon svg {
  width: 50%;
  height: 100%;
  opacity: 0.3; }

.big-caption {
  font-size: 28px; }

.dynamic-text {
  font-size: 1.1em !important;
  font-weight: 400; }

.full-height {
  height: 100%; }

.create-page {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px; }

.quiz-answercounter-container {
  padding-top: 100px; }

.quiz-top-section {
  height: 25vh;
  text-align: center; }

.quiz-complete-section {
  height: calc(100vh - 50px);
  text-align: center; }

.quiz-middle-and-bottom-section {
  height: 75vh; }

.quiz-answer-chart {
  max-width: 300px;
  float: right;
  padding-right: 20px; }

.quiz-middle-section {
  height: 60vh;
  text-align: center; }

.align-bottom {
  position: absolute;
  bottom: 0;
  left: 0; }

.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@media (max-width: 1279px) {
  .hide-mobile {
    display: none !important; } }

@media (min-width: 1280px) {
  .hide-desktop {
    display: none !important; } }

.dark-mode {
  background-color: #191414;
  color: #fff; }

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

.demo {
  position: absolute;
  left: 50%;
  top: 65%;
  width: 500px;
  height: 140px;
  margin-top: -70px;
  padding: 10px;
  border-radius: 20px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  .demo__colored-blocks {
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 0;
    width: 500px;
    height: 100%;
    margin-left: -250px;
    padding: 10px;
    border-radius: 20px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    -webkit-animation: demoAnim 4s ease-in-out infinite, contAnim 4s infinite;
            animation: demoAnim 4s ease-in-out infinite, contAnim 4s infinite; }
    .demo__colored-blocks-rotater {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      -webkit-animation: rotation 1.3s linear infinite;
              animation: rotation 1.3s linear infinite; }
    .demo__colored-blocks-inner {
      overflow: hidden;
      position: relative;
      height: 100%;
      background: #32386D;
      border-radius: inherit; }
  .demo__colored-block {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 300%;
    height: 300%;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0; }
    .demo__colored-block:nth-child(1) {
      -webkit-transform: rotate(0deg) skewX(0deg);
              transform: rotate(0deg) skewX(0deg);
      background-color: #66b1bb; }
    .demo__colored-block:nth-child(2) {
      -webkit-transform: rotate(90deg) skewX(0deg);
              transform: rotate(90deg) skewX(0deg);
      background-color: #EF9A9A; }
    .demo__colored-block:nth-child(3) {
      -webkit-transform: rotate(180deg) skewX(0deg);
              transform: rotate(180deg) skewX(0deg);
      background-color: #CE93D8; }
    .demo__colored-block:nth-child(4) {
      -webkit-transform: rotate(270deg) skewX(0deg);
              transform: rotate(270deg) skewX(0deg);
      background-color: #9db484; }
  .demo__inner {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%; }
  .demo__numbers {
    overflow: visible;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
    margin-top: -50px; }
    .demo__numbers-path {
      fill: none;
      stroke-width: 10px;
      stroke: #fff;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 0, 518.05507;
      stroke-dashoffset: 0;
      -webkit-animation: numAnim 4s ease-in-out infinite;
              animation: numAnim 4s ease-in-out infinite;
      opacity: 0; }
  .demo__text {
    position: absolute;
    left: 50%;
    top: 0;
    width: 500px;
    height: 100%;
    margin-left: -250px;
    text-align: center;
    line-height: 140px;
    font-size: 100px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 15px;
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
    -webkit-animation: hideText 4s infinite;
            animation: hideText 4s infinite; }

.demo_slow {
  position: absolute;
  left: 50%;
  top: 65%;
  width: 500px;
  height: 140px;
  margin-top: -70px;
  padding: 10px;
  border-radius: 20px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  .demo_slow__colored-blocks {
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 0;
    width: 500px;
    height: 100%;
    margin-left: -250px;
    padding: 10px;
    border-radius: 20px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    -webkit-animation: demoAnim 5.6s ease-in-out infinite, contAnim 5.6s infinite;
            animation: demoAnim 5.6s ease-in-out infinite, contAnim 5.6s infinite; }
    .demo_slow__colored-blocks-rotater {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      -webkit-animation: rotation 1.3s linear infinite;
              animation: rotation 1.3s linear infinite; }
    .demo_slow__colored-blocks-inner {
      overflow: hidden;
      position: relative;
      height: 100%;
      background: #32386D;
      border-radius: inherit; }
  .demo_slow__colored-block {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 300%;
    height: 300%;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0; }
    .demo_slow__colored-block:nth-child(1) {
      -webkit-transform: rotate(0deg) skewX(0deg);
              transform: rotate(0deg) skewX(0deg);
      background-color: #66b1bb; }
    .demo_slow__colored-block:nth-child(2) {
      -webkit-transform: rotate(90deg) skewX(0deg);
              transform: rotate(90deg) skewX(0deg);
      background-color: #EF9A9A; }
    .demo_slow__colored-block:nth-child(3) {
      -webkit-transform: rotate(180deg) skewX(0deg);
              transform: rotate(180deg) skewX(0deg);
      background-color: #CE93D8; }
    .demo_slow__colored-block:nth-child(4) {
      -webkit-transform: rotate(270deg) skewX(0deg);
              transform: rotate(270deg) skewX(0deg);
      background-color: #9db484; }
  .demo_slow__inner {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%; }
  .demo_slow__numbers {
    overflow: visible;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
    margin-top: -50px; }
    .demo_slow__numbers-path {
      fill: none;
      stroke-width: 10px;
      stroke: #fff;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 0, 518.05507;
      stroke-dashoffset: 0;
      -webkit-animation: numAnim 5.6s ease-in-out infinite;
              animation: numAnim 5.6s ease-in-out infinite;
      opacity: 0; }
  .demo_slow__text {
    position: absolute;
    left: 50%;
    top: 0;
    width: 500px;
    height: 100%;
    margin-left: -250px;
    text-align: center;
    line-height: 140px;
    font-size: 100px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 15px;
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
    -webkit-animation: hideText 5.6s infinite;
            animation: hideText 5.6s infinite; }

@-webkit-keyframes contAnim {
  15%,
  100% {
    margin-left: -250px;
    width: 500px; }
  25%,
  90% {
    margin-left: -70px;
    width: 140px; } }

@keyframes contAnim {
  15%,
  100% {
    margin-left: -250px;
    width: 500px; }
  25%,
  90% {
    margin-left: -70px;
    width: 140px; } }

@-webkit-keyframes numAnim {
  15% {
    stroke-dasharray: 0, 518.05507;
    stroke-dashoffset: 0;
    opacity: 0; }
  25%,
  41% {
    opacity: 1;
    stroke-dasharray: 144.42566, 518.05507;
    stroke-dashoffset: -40; }
  53%,
  66% {
    stroke-dasharray: 136.02162, 518.05507;
    stroke-dashoffset: -227.2387; }
  76% {
    stroke-dasharray: 113.47512, 518.05507;
    stroke-dashoffset: -445.89957; }
  88%,
  100% {
    stroke-dasharray: 72.15549, 518.05507;
    stroke-dashoffset: -445.89957; }
  92% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes numAnim {
  15% {
    stroke-dasharray: 0, 518.05507;
    stroke-dashoffset: 0;
    opacity: 0; }
  25%,
  41% {
    opacity: 1;
    stroke-dasharray: 144.42566, 518.05507;
    stroke-dashoffset: -40; }
  53%,
  66% {
    stroke-dasharray: 136.02162, 518.05507;
    stroke-dashoffset: -227.2387; }
  76% {
    stroke-dasharray: 113.47512, 518.05507;
    stroke-dashoffset: -445.89957; }
  88%,
  100% {
    stroke-dasharray: 72.15549, 518.05507;
    stroke-dashoffset: -445.89957; }
  92% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes rotation {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotation {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes demoAnim {
  15% {
    border-radius: 20px;
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  30%,
  43% {
    border-radius: 50%;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); }
  52%,
  65% {
    border-radius: 0;
    -webkit-transform: rotate(720deg);
            transform: rotate(720deg); }
  78%,
  90% {
    border-radius: 50%;
    -webkit-transform: rotate(1080deg);
            transform: rotate(1080deg); }
  100% {
    border-radius: 20px;
    -webkit-transform: rotate(1440deg);
            transform: rotate(1440deg); } }

@keyframes demoAnim {
  15% {
    border-radius: 20px;
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  30%,
  43% {
    border-radius: 50%;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); }
  52%,
  65% {
    border-radius: 0;
    -webkit-transform: rotate(720deg);
            transform: rotate(720deg); }
  78%,
  90% {
    border-radius: 50%;
    -webkit-transform: rotate(1080deg);
            transform: rotate(1080deg); }
  100% {
    border-radius: 20px;
    -webkit-transform: rotate(1440deg);
            transform: rotate(1440deg); } }

@-webkit-keyframes hideText {
  15%,
  100% {
    opacity: 1; }
  20%,
  96% {
    opacity: 0; } }

@keyframes hideText {
  15%,
  100% {
    opacity: 1; }
  20%,
  96% {
    opacity: 0; } }

