@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Nunito', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'Nunito', source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


$answer-color1: #80DEEA;
$answer-color2: #EF9A9A;
$answer-color3: #CE93D8;
$answer-color4: #C5E1A5;
.player-controlls-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: ". up ." "left center right" ". down .";
}

.player-controlls-icon {
    font-size: 20vw !important;
}

.player-controlls-action {
    height: 33vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.card-button{
    cursor:pointer;
}
.home-page a, .toolbar a{
    text-decoration: none;
}
.toolbar a {
    color:white;
}

.player-controlls-left {
    grid-area: left;
}

.player-controlls-right {
    grid-area: right;
}
.player-controlls-middle{
    grid-area: center;
}

.player-controlls-up {
    grid-area: up;
}

.player-controlls-down {
    grid-area: down;
}

@media (orientation:landscape) {
    .player-controlls-action {
        height: 33vh;
    }
}

.button-answer-icon svg {
    width: 50%;
    height: 100%;
    opacity: 0.3;
}

.big-caption {
    font-size: 28px;
}

.dynamic-text {
    font-size: 1.1em !important;
    font-weight: 400;
}

.full-height {
    height: 100%;
}

.create-page {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
}

.quiz-answercounter-container {
    padding-top: 100px;
}

.quiz-top-section {
    height: 25vh;
    text-align: center;
}

.quiz-complete-section {
    height: calc(100vh - 50px);
    text-align: center;
}

.quiz-middle-and-bottom-section {
    height: 75vh;
}

.quiz-answer-chart {
    max-width: 300px;
    float: right;
    padding-right: 20px;
}

.quiz-middle-section {
    height: 60vh;
    text-align: center;
}

.align-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
}

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media (max-width: 1279px) {
    .hide-mobile {
        display: none !important;
    }
}

@media (min-width: 1280px) {
    .hide-desktop {
        display: none !important;
    }
}
.dark-mode{
    background-color:#191414;
    color: #fff;
}
